.medium-btn,
.large-btn,
.small-btn,
.btn-3{
    display: inline;
    background-color:#fff;
    color: #333333; 
   padding: 13px 30px;
   font-weight: bold;
   line-height: 14px;
   font-size: 14px;
   text-transform: uppercase;
}
.small-btn{
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
    color:#fff;
    border-radius: 15px;
}
.medium-btn{
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
    color:#fff;
}
.large-btn{
     color:#fff;
}
.btn-3{
    border:1px solid #e3e3e3;
}
.medium-btn:hover,
.large-btn:hover,
.small-btn:hover{
    background-color: #000;
    color: #fff;
}






.rw-sentence span{
    font-size: 48px;
    font-weight: normal;
}
.rw-words{
    display: inline;
    text-indent: 10px;
}
.rw-words-1 span{
    position: absolute;
    opacity: 0;
    overflow: hidden;
    color: #6b969d;
    -webkit-animation: rotateWord 18s linear infinite 0s;
    -ms-animation: rotateWord 18s linear infinite 0s;
    animation: rotateWord 18s linear infinite 0s;
    left: 0;
    right: 0;
    margin:auto; 
}
.rw-words-1 span:nth-child(2) { 
    -webkit-animation-delay: 3s; 
    -ms-animation-delay: 3s; 
    animation-delay: 3s; 
    color: #6b889d;
}
.rw-words-1 span:nth-child(3) { 
    -webkit-animation-delay: 6s; 
    -ms-animation-delay: 6s; 
    animation-delay: 6s; 
    color: #6b739d; 
}
.rw-words-1 span:nth-child(4) { 
    -webkit-animation-delay: 9s; 
    -ms-animation-delay: 9s; 
    animation-delay: 9s; 
    color: #7a6b9d;
}
.rw-words-1 span:nth-child(5) { 
    -webkit-animation-delay: 12s; 
    -ms-animation-delay: 12s; 
    animation-delay: 12s; 
    color: #8d6b9d;
}
.rw-words-1 span:nth-child(6) { 
    -webkit-animation-delay: 15s; 
    -ms-animation-delay: 15s; 
    animation-delay: 15s; 
    color: #9b6b9d;
}
@-webkit-keyframes rotateWord {
    0% { opacity: 0; }
    2% { opacity: 0; -webkit-transform: translateY(-30px); }
    5% { opacity: 1; -webkit-transform: translateY(0px);}
    17% { opacity: 1; -webkit-transform: translateY(0px); }
    20% { opacity: 0; -webkit-transform: translateY(30px); }
    80% { opacity: 0; }
    100% { opacity: 0; }
}
@-ms-keyframes rotateWord {
    0% { opacity: 0; }
    2% { opacity: 0; -ms-transform: translateY(-30px); }
    5% { opacity: 1; -ms-transform: translateY(0px);}
    17% { opacity: 1; -ms-transform: translateY(0px); }
    20% { opacity: 0; -ms-transform: translateY(30px); }
    80% { opacity: 0; }
    100% { opacity: 0; }
}
@keyframes rotateWord {
    0% { opacity: 0; }
    2% { opacity: 0; -webkit-transform: translateY(-30px); transform: translateY(-30px); }
    5% { opacity: 1; -webkit-transform: translateY(0px); transform: translateY(0px);}
    17% { opacity: 1; -webkit-transform: translateY(0px); transform: translateY(0px); }
    20% { opacity: 0; -webkit-transform: translateY(30px); transform: translateY(30px); }
    80% { opacity: 0; }
    100% { opacity: 0; }
}
@media screen and (max-width: 768px){
    .rw-sentence { font-size: 18px; }
}
@media screen and (max-width: 320px){
    .rw-sentence { font-size: 9px; }
}














