@import url(https://fonts.googleapis.com/css?family=Ubuntu:400,300,300italic,400italic,500,500italic,700,700italic&subset=latin,cyrillic-ext,latin-ext,cyrillic,greek-ext,greek);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,400italic,700,700italic);
@import url(https://fonts.googleapis.com/css?family=Noto+Serif:400,400italic,700);
/*
    font-family: 'Noto Sans', sans-serif;
    font-family: 'Noto Serif', serif;
    font-family: 'Ubuntu', sans-serif;
*/

*{
	box-sizing:border-box;
	-moz-box-sizing:border-box;
	-webkit-box-sizing:border-box;
	-o-box-sizing:border-box;
	-ms-box-sizing:border-box;	
}
body {
    color: #777777;
    font-family: 'Ubuntu', sans-serif;
    font-size: 16px;
    font-weight: normal;
    background-color: #ffffff;
}

/* Heading Typo */
h1, h2, h3, h4, h5, h6 {
    color: #000;
    font-family: 'Noto Serif', serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
    margin: 0px 0px 0px 0px;
}

h1 {
    font-size: 62px;
}

h2 {
    font-size: 50px;
}

h3 {
    font-size:32px;
}

h4 {
    font-size: 30px;
}

h5 {
    font-size: 24px;
}

h6 {
    font-size: 20px;
}

/* h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: #ff401b;
   font-family: 'Noto Serif', serif;
} */

p a {
    color: #fff;
}
/* Peragraph Typo */
p{
    line-height: 26px;
}

a {
    color: #f5f9fa;
    text-decoration: none;
	font-family: 'Noto Sans', sans-serif;
}

a:hover, a:focus, a:active {
    outline: none;
    text-decoration: none;
}

ul {
   margin:0px;
   padding:0px;
}
li {
    
    list-style: none;
}
ul ul {
    margin:0px;
	padding:0px;
}
ol {
    float: none;
    list-style: decimal;
    padding-left: 15px;
}
ol ol {
    margin-left: 20px;
}
ol li {
	list-style: decimal;
	width: 100%;
}
.text{
    float: left;
    width: 100%;
}
figure {
	float:left;
	width:100%;
    position: relative;
	overflow:hidden;
}

.clear {
    clear: both;
    font-size: 0;
    line-height: 0;
}
figure img{
	float:left;
	width:100%;
}
img {
    max-width: 100%;
}

strong {
    color: #3d3d3d;
}

iframe {
    border: none;
    float: left;
    width: 100%;
}
/* Form Input Typo */
select {
    border: 1px solid #d2d2d2;
    color: #3d3d3d;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 300;
    height: 40px;
    padding: 8px 12px;
    width: 100%;
}

label {
    color: #3d3d3d;
    display: block;
    font-weight: 400;
    margin-bottom: 10px;
}

button {
    border: none;
}

textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"], .uneditable-input {
    -moz-box-sizing: border-box;
  	-webkit-box-sizing: border-box;
	box-sizing: border-box;
    border: 1px solid #dadada;
    display: inline-block;
    font-size: 16px;
    font-weight: normal;
    line-height: 16px;
    outline: none;
    vertical-align: middle;
}

form p {
    float: left;
    position: relative;
    width: 100%;
}

form p span i {
    color: #474747;
    left: 16px;
    position: absolute;
    top: 13px;
}
table {
    background-color: transparent;
    max-width: 100%;
    width: 100%;
}
th {
    text-align: left;
}
table > thead > tr > th, table > tbody > tr > th, table > tfoot > tr > th, table > thead > tr > td, table > tbody > tr > td, table > tfoot > tr > td {
    border-top: 1px solid #dddddd;
    border: 1px solid #dddddd;
    line-height: 2.5;
    padding-left: 7px;
    vertical-align: top;
}
table thead tr th {
    border-top: 1px solid #d2d2d2;
}
.table > caption + thead > tr:first-child > th, .table > colgroup + thead > tr:first-child > th, .table > thead:first-child > tr:first-child > th, .table > caption + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > td, .table > thead:first-child > tr:first-child > td {
	border-top: 1px solid #d2d2d2;
}
table > thead > tr > th {
    border-bottom: 2px solid #d2d2d2;
    vertical-align: bottom;
}
table > caption + thead > tr:first-child > th, table > colgroup + thead > tr:first-child > th, table > thead:first-child > tr:first-child > th, table > caption + thead > tr:first-child > td, table > colgroup + thead > tr:first-child > td, table > thead:first-child > tr:first-child > td 
{
	border-bottom: 0;
}
table > tbody + tbody {
    border-top: 2px solid #d2d2d2;
}
.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    padding: 12px 16px;
}
p ins {
    color: #999;
}
dl dd {
    margin-left: 20px;
}
address {
    font-style: italic;
}
::-webkit-input-placeholder {color: #666666; opacity: 1;}
:-moz-placeholder { color: #666666; opacity: 1; }
::-moz-placeholder { color: #666666; opacity: 1; }
:-ms-input-placeholder {color: #666666; opacity: 1;}