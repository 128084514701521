/*======================================
  Selectric v1.9.3
======================================*/
.selectric-wrapper {
	position: relative;
	cursor: pointer;
	border-radius: 3px;
	border: 1px solid #fae5e5;
	min-height: 48px;
	width: 100%;	
	background-color:#fff;
	float:left;
}
.selectric-responsive {
	width: 100%;
}
.selectric {
	position: relative;
	width:100%;
	float:left;
}
.selectric .label {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0px;
	font-size: 14px;
	line-height: 44px;
	height: 44px;
	padding:0px 20px;
	color:#666;
	text-align:left;
	width:100%;
	float:left;
	position:static
}
.selectric .button {
	display: block;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	height: 44px;
	color: #BBB;
	text-align: right;
	font-size:22px;
	padding:7px 12px;

}
.selectric-hover .selectric {
	border-color: #C4C4C4;
}
.selectric-hover .selectric .button {
	color: #A2A2A2;
}
.selectric-hover .selectric .button:after {
	border-top-color: #A2A2A2;
}
.selectric-open {
	z-index: 100;
}
.selectric-open .selectric {
	border-color: #C4C4C4;
}
.selectric-open .selectric-items {
	display: block;
	border-radius:3px;
	overflow:hidden;
}
.selectric-disabled {
	filter: alpha(opacity=50);
	opacity: 0.5;
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.selectric-hide-select {
	position: relative;
	overflow: hidden;
	width: 0;
	height: 0;
	float:left;
	width:100%;
}
.selectric-hide-select select {
	position: absolute;
	left: -100%;
	display: none;
}
.selectric-input {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	margin: 0 !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	outline: none !important;
	border: none !important;
    font: 0/0 a !important;
	background: none !important;
}
.selectric-temp-show {
	position: absolute !important;
	visibility: hidden !important;
	display: block !important;
}
/* Items box */
.selectric-items {
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	background: #F8F8F8;
	z-index: -1;
	box-shadow: 0 0 10px -6px;
}
.selectric-items .selectric-scroll {
	height: 100%;
	overflow: auto;
}
.selectric-above .selectric-items {
	top: auto;
	bottom: 100%;
}

.selectric-items ul, .selectric-items li {
	list-style: none;
	padding:5px;
	margin: 0;
	font-size: 12px;
	line-height: 20px;
	min-height: 20px;
}
.selectric-items li {
	display: block;
	padding: 6px 8px;
	color: #666;
	cursor: pointer;
	background-color:#fff;
	border-radius:0px !important;
	font-size:14px;
}
.selectric-items li.selected {
	color: #fff;
}
.selectric-items li:hover {
	color: #fff;
}
.selectric-items .disabled {
	filter: alpha(opacity=50);
	opacity: 0.5;
	cursor: default !important;
	background: none !important;
	color: #666 !important;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.selectric-items .selectric-group .selectric-group-label {
	font-weight: bold;
	padding-left: 10px;
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background: none;
	color: #444;
}
.selectric-items .selectric-group.disabled li {
	filter: alpha(opacity=100);
	opacity: 1;
}
.selectric-items .selectric-group li {
	padding-left: 25px;
}
