/******************************************BUTTON BOTTOM SHADOW EFFECT****************************************************/
.shadow-shadow{
    backface-visibility: hidden;
    display: inline-block;
    position: relative;
    transform: translateZ(0px);
    transition-duration: 0.3s;
    transition-property: transform;
    vertical-align: middle;
}
.shadow-shadow::before {
    background: rgba(0, 0, 0, 0) radial-gradient(ellipse at center center , rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%) repeat scroll 0 0;
    content: "";
    height: 10px;
    left: 5%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    transition-duration: 0.3s;
    transition-property: transform, opacity;
    width: 90%;
    z-index: -1;
}
.shadow-shadow:hover{
    transform: translateY(-3px);
}
.shadow-shadow:hover::before
{
    opacity: 1;
    transform: translateY(5px);
}

/******************************************BUTTON SHINE EFFECT****************************************************/
.btn-shine{
    position: relative;
    overflow: hidden;
}
.btn-shine::before {
    background-color: rgba(255, 255, 255, 0.8);
    content: "";
    height: 100%;
    left: 100%;
    position: absolute;
    top: 0;
    transform: rotate(42deg);
    -webkit-transform:  rotate(42deg);
    -moz-transform:    rotate(42deg);
    -ms-transform:  rotate(42deg);
    -o-transform:   rotate(42deg);
    transition: all 0.3s ease-in-out 0s;
    width: 220%;
    z-index: 10;
}
.btn-shine:hover::before {
    background-color: rgba(255, 255, 255, 0.15);
    left: -150%;
}
/******************************************ALL FIGURE AFTER AND BEFORE DEFAULT PROPERTIES ****************************************************/
.kf-home-banner figure:before,
.kf-room-grid figure:before,
.kf_content-des .thumb figure:before,
.kf-tab-services-wrap:before,.news-letter-bg:after,
.kf-testimonial-bg::before,
.blog-detail-thumb figure a:before,
.about_autor:before,
.related-post figure:before,
.inner-banner:before,
.hotel-masonery-thumb:before,
.kf-popular-post ul li:before,
footer:before

{
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    content: "";
}